<template>
    <div
      class="
        text-center
        d-flex
        align-items-center
        justify-content-between
        full-screen
      "
    >
    {{ id }}
      <div class="text-center mx-auto">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          type="grow"
          variant="primary"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { BSpinner } from "bootstrap-vue";
  import axios from "axios";
  import { getUserToken } from "@/auth/utils";
  import useJwt from "@/auth/jwt/useJwt";
  import Cookies from 'js-cookie';

  export default {
    components: {
      BSpinner,
    },
    props: {
      customValue: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        loggingIn: true,
        url:''
      };
    },
    methods: {
      validateAuthCode() {
        if (!this.$route.query.code || !this.$route.query.state) {
          return this.$router.push("/dashboard");
        }
        const getClient = Cookies.get("salesforceId");
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/salesforce/oauth2/success`,
            {
              code: this.$route.query.code,
              state: this.$route.query.state,
              clientID: getClient,
              codeChallenge:this.$route.query.code_challenge
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then((response) => {
            const clientSlug = response.data.slug; 
            this.$router.push(
              `/client/${clientSlug}`
            );
            Cookies.remove("salesforceId");
          })
          .catch((error) => {
            console.log(error.response);
            this.$router.push("/dashboard");
          });
      },
    },
    mounted() {
      this.validateAuthCode();
    },
    computed: {
      id() {
        return this.$route.query.name;
      }
    }
  };
  </script>
  
  <style scoped>
  .full-screen {
    height: 100vh;
  }
  </style>